<template>
  <div class="page-wrapper">
    <div class="auth-bg">
       <router-view/>
    </div>
    <!-- <div class="auth-bg-effect">
        <div class="first-effect"></div>
        <div class="second-effect"></div>
        <div class="third-effect"></div>
        <div class="fourth-effect"></div>
    </div> -->

</div>
</template>

<script>
export default {

}
</script>

<style>

</style>