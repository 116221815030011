<template>
  <div>
    <div class="authentication-box">
            <h3 class="text-center">NEW USER</h3>
            <h6 class="text-center">Enter your Username and Password For Login or Signup</h6>
            <div class="card mt-4 p-4">
                <form class="theme-form" id="signup_form" @submit.prevent="signup()">
                    <div class="mb-3">
                        <label class="col-form-label">User Name</label>
                        <input type="text" name="name" class="form-control" placeholder="enter-name">
                    </div>
                    <div class="mb-3">
                        <label class="col-form-label">Email</label>
                        <input type="email" name="email" class="form-control" placeholder="test@gmail.com">
                    </div>
                     <div class="mb-3">
                        <label class="col-form-label">Password</label>
                        <input type="password" name="password" class="form-control" placeholder="enter-password">
                    </div>
                    <div class="mb-3">
                        <label class="col-form-label">Re-Type Password</label>
                        <input type="password" name="password_confirmation" class="form-control" pplaceholder="re-type password">
                    </div>
                 
                    <div class="row g-2">
                        <div class="col-sm-3">
                            <button type="submit" class="btn btn-secondary">Sign Up</button>
                        </div>
                        <div class="col-sm-8">
                            <div class="text-start mt-2 m-l-20">
                                Are you already user?&nbsp;&nbsp;
                               <router-link :to="{name:'login'}">Signin</router-link>
                            </div>
                        </div>

                    </div>
                    <div class="form-divider"></div>
                   
                </form>
            </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    methods:{
         ...mapMutations([
            'set_auth_token',
            'set_auth_info',
        ]),
        signup: function(){
           let form_data = new FormData(document.getElementById('signup_form'));

           window.axios.post('/user/register',form_data)
           .then(res=>{
               console.log(res.data);
                this.set_auth_token({ token:res.data.access_token});
                this.set_auth_info(res.data.user);
                this.$toast.success( this.get_auth_role_name+' '+'account create success');
               
            })
           .catch(err=>{
               console.log(err.response);
           })

        }
    },
     computed:{
        ...mapGetters([
            'get_auth_role_name'
        ])
    },

}
</script>

<style>

</style>