<template>
  <div>
    <div class="row justify-content-center mt-4">
      <div class="col-sm-12 col-md-8 col-lg-9">
        <div class="card">
          <div class="card-header">
            <h4>New Entry</h4>
          </div>
          <div class="card-body">
            <form action="" @submit.prevent="save_entry">
              <div class="form-group">
                <label for=""  class="mt-3">User Name</label>
                <!-- <input type="text" class="form-control" /> -->
                 <Select2 class="form-control py-3" 
                  v-model="userValue" 
                 :options="userOption" 
                 :settings="{                   
                   multiple: true,
                   placeholder: `search user and select`,
                   allowClear: true, 
                   }" 
                 @change="userChangeEvent($event)" 
                 @select="userSelectEvent($event)"
                  />
              </div>
              <div class="form-group">
                <label for="" class="mt-3">Book Name</label>
                 <Select2 class="form-control py-3" 
                  v-model="booksValue" 
                 :options="booksOption" 
                 :settings="{                   
                   multiple: true,
                   placeholder: `search books and select`,
                   allowClear: true, 
                   }" 
                 @change="booksChangeEvent($event)" 
                 @select="booksSelectEvent($event)"
                  />
              </div>
              <div class="form-group">
                <label for="" class="mt-3">Time</label>
                <input type="time" v-model="time" class="form-control" />
              </div>
              <div class="form-group">
                <label for="" class="mt-3">Date</label>
                <input type="date" v-model="date" class="form-control" />
              </div>
              <div class="form-group">
                <label for="" class="mt-3">Return Date</label>
                <input type="date" v-model="return_date" class="form-control" />
              </div>
              <div class="form-group" >
                <button type="button" class="btn btn-secondary mt-3">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "new-entry",
  data: function(){
       return {
            userValue: '',
            booksValue: '',
            // userOption: ['op1', 'op2', 'op3'] // or [{id: key, text: value}, {id: key, text: value}]
            userOption: [],
            booksOption: [],
            time: '',
            date: '',
            return_date: ''
        }
  },
  created: function(){
    this.getData();
    this.getBookData();
  },
  methods: {
    getData: function () {
      window.axios.get('/user/user-list-for-select2')
      .then(res =>{
        // console.log(res.data);
        this.userOption = res.data;
      })
    },
    getBookData: function () {
      window.axios.get('/book-list/book-list-for-select2')
      .then(res =>{
         console.log(res.data);
         this.booksOption = res.data;
      })
    },

    userChangeEvent: function(){
          // console.log(val);
      },
    userSelectEvent: function({id, text}){
        console.log({id, text}, this.userValue);
    },
    booksChangeEvent: function(){
          // console.log(val);
      },
    booksSelectEvent: function({id, text}){
        console.log({id, text}, this.booksValue);
    },
    save_entry: function(){
      
    }
  },
};
</script>

<style>

</style>