<template>
  <div>
    <div class="authentication-box">
            <h4 class="text-center">LOGIN</h4>
            <router-link :to="{name:'signup'}">SignUp</router-link>
            <h6 class="text-center">Enter your Username and Password For Login or Signup</h6>
            <div class="card mt-4 p-4 mb-0">
                <form class="theme-form" id="login_form" @submit.prevent="login_submit">
                    <div class="mb-3">
                        <label class="col-form-label pt-0">Your Name</label>
                        <input type="text" name="email" class="form-control">
                    </div>
                    <div class="mb-3">
                        <label class="col-form-label">Password</label>
                        <input type="password" name="password" class="form-control" placeholder="Password">
                    </div>
                    <div class="form-check checkbox">
                        <input class="form-check-input" id="checkbox1" type="checkbox">
                        <label class="form-check-label" for="checkbox1">Remember me</label>
                    </div>
                    <!-- <div class="form-group">
                        <button type="button" class="btn btn-success m-1" @click.prevent="login(true,'admin')">Admin</button>
                        <button type="button" class="btn btn-success m-1" @click.prevent="login(true,'student')">Student</button>
                        <button type="button" class="btn btn-success m-1" @click.prevent="login(true,'management')">Management</button>
                      
                    </div> -->
                    <div class="row mt-3">
                        <div class="col-md-4">
                            <button type="submit" class="btn btn-primary">LOGIN</button>
                        </div>
                    </div>
                </form>
            </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
    methods:{
        ...mapMutations([
            'set_auth_role_name',
            'set_check_auth_status',
            'set_auth_token',
            'set_auth_info',
        ]),
        // login: function(status,role_name){
        //     this.set_auth_role_name(role_name);
        //     this.set_check_auth_status(status);

        // },
        login_submit: function(){
            let form_data = new FormData(document.getElementById('login_form'));
            window.axios.post('/user/login',form_data)
            .then(res=>{
                //  console.log(res.data);
                this.set_auth_token({ token:res.data.access_token});
                this.set_auth_info(res.data.user);
                this.$toast.success( this.get_auth_role_name+' '+'login success');
                // localStorage.setItem('b_token', res.data.user.access_token);
            })
            .catch(err=>{
                console.log(err.response);
            })
        }

    },
    computed:{
        ...mapGetters([
            'get_auth_role_name'
        ])
    },

}
</script>

<style>

</style>