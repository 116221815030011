<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <h4 class="">Book List</h4>
            <input type="text" @keyup="search($event.target.value)" class="form-control w-50" placeholder="search" style="max-width:400px;">

          </div>
          <div class="card-body table-responsive">
            <button class="btn btn-light btn-sm mb-2" v-if="selected_data.length > 0" @click.prevent="delete_multiple()" >
                <i class="fas fa-minus-circle text-success" style="margin-right:5px; font-size:20px"></i><span class="text-dark" >delete {{selected_data.length}} Items</span>
            </button>
            <table class="table table-bordered table-striped text-center">
              <thead>
                <tr>
                  <th>
                    <input type="checkbox" @click="check_all()" name="form-check" id="check_all">
                  </th>
                  <th>#</th>
               
                  <th class="text-center" style="width: 10%">Image</th>
                  <th class="text-center" style="width: 30%">Book Name</th>
                  <th class="text-center" style="width: 20%">Author</th>
                  <th class="text-center" style="width: 10%">Section</th>
                  <th class="text-center" style="width: 30%">Action</th>
                </tr>
              </thead>
              <tbody >
                <tr v-for="(book,index) in book_list.data" :key="index">
                  <td>
                     <input
                      v-if="selected_data.includes(book.id)"
                      checked
                      type="checkbox"
                      @change="add_to_selected(book.id)"
                      class="form-check"
                    />
                    <input
                      v-else
                      type="checkbox"
                      @change="add_to_selected(book.id)"
                      class="form-check"
                    />
                  </td>
                  <td>{{ index+1 }}</td>
                  <td>
                    <img v-if="book.image.split('/')[0] === 'uploads'" :src="`${get_server_url}/${book.image}`" style="height:70px" alt="image" />
                    <img v-else :src="`http://${book.image}`" style="height:70px" alt="image" />
                  </td>
                  <td>{{ book.name }}</td>
                  <td>{{ book.author }}</td>
                  <td>{{ book.section }}</td>
                  <td>
                    <div class="d-flex justify-content-end">
                      <a href="#" class="btn btn-sm btn-primary mx-1"
                        >New Entry</a
                      >
                     <router-link
                        :to="{ name: 'bookEdit', params: { id: book.id } }"
                        class="btn btn-sm btn-warning mx-1"
                        >Edit</router-link
                      >
                      <a href="#" @click.prevent="delete_book(book,index)" class="btn btn-sm btn-danger mx-1">Delete</a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer m-auto">
            <pagination  v-model="page" :records="total" :per-page="per_page" @paginate="getData"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "admin-base",
  data: function(){
    return {
      book_list: {},
      page: 1,
      per_page: 0,
      total: 0,
      selected_data: [],
      search_key: ''
    }
  },          
  created: function(){
    this.getData();
  },
  methods: {
    getData: function (page=1) {
      let url = `/book-list?page= ${page}`;
      if(this.search_key.length > 0){
        url += `&key=${this.search_key}`;
      }
      // window.axios.get('/book-list?page=' + page)
      window.axios.get(url)
      .then(res=>{
        // console.log(res.data);
        this.book_list = res.data;
        this.total = res.data.total;
        this.per_page = res.data.per_page;
       
      })
    },
    delete_book: function(book,index){
      let con =confirm("Are sure to delete?" );
      // console.log(index);
      if(con){
        window.axios.post("/book-list/delete",{id: book.id})
        .then(res =>{
              this.book_list.data.splice(index,1);
              this.$toast.success(res.data.name+ ' ' +'Book Delete success');
            //  this.getData();
          // console.log(res.data);
        })
      }

    },
    add_to_selected: function(id){
      // this.selected_data.push(id);
      // console.log(this.selected_data);
      if(this.selected_data.includes(id)){
        this.selected_data = this.selected_data.filter(item=>item!=id);
      }else{
        this.selected_data.push(id);
      }
      // console.log(this.selected_data);
    },
    check_all: function(){
        this.book_list.data.map(item=>{
          // return this.selected_data.push(item.id);
      if(this.selected_data.includes(item.id)){
        this.selected_data = this.selected_data.filter(item2=>item2!=item.id);
      }else{
        this.selected_data.push(item.id);
      }
      return 0;
     })
  },

    delete_multiple: function(){
     let con =confirm("Are sure to delete?"+ " "+"("+this.selected_data.length+")"+ " "+'items');
      if(con){
        window.axios.post("/book-list/delete-multiple",{ids: this.selected_data})
        .then(() =>{
          //  console.log(res.data);
           this.selected_data =[];
            this.$toast.success('Selected Books Deleted Successfully');
             this.getData();
             window.$('#check_all').prop('checked', false);
        })
      }
    },
    search: function(key){
      // console.log(key);
      this.search_key = key;
      this.getData();
    }
  },
  computed:{
    ...mapGetters([
      'get_server_url',
    ])
  }
};
</script>

<style>

</style>